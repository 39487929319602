import React from "react"
import Layout from "../components/layout"
import { Link, graphql } from "gatsby"

import { Card, Button } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import LazyAnimationImage from '../components/lazeeAnimationImage'

import Head from "../components/head"

const Confirmation = ({data}) => {
  
    return(
      <LazyAnimationImage>
        <div>
            <Layout>
              <Head title="Confirmation" />
                <Card style={{ width: '70%', margin: `20vh auto`, color: `rgb(0, 98, 0)` }}>
                  <Card.Body>
                    <Card.Title
                      style={{fontSize: `3rem`, margin: `1rem`}}
                      >Thank you!
                    </Card.Title>
                      <Card.Subtitle 
                        style={{fontSize: `2rem`, margin: `1rem`}}
                        className="mb-2">Your order has been received!
                      </Card.Subtitle>
                    <Card.Text
                    style={{margin: `1rem`}}
                    >
                      We will call you within one business day to confirm your order.
                    </Card.Text>
                    <Link to="/">
                      <Button className="confirmation-button" variant="outline-success">
                        Vicenza's Crostoli Home
                      </Button>
                    </Link>
                  </Card.Body>
                </Card>
            </Layout>
        </div>
      </LazyAnimationImage>
    )
}

export default Confirmation

// export const pageQuery = graphql`
//   query { 
//     easterWall: file(relativePath: { eq: "Easter-2021-Background-WhiteWall.jpg" }) {
//       childImageSharp {
//         fluid(maxWidth: 1200, quality: 50) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `